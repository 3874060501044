<template>
  <div>
    <Navigation />
    <div class="wrap-box">
      <h3 class="heading">Публічна інформація</h3>
    </div>
    <main class="public-documents">
      <section class="document-categories">
        <h2 class="document-categories-heading">Типи документів</h2>
        <ul class="categories-content">
          <li v-for="(category, i) in documentCategories" :key="i" class="document-category">
            <p @click="[chooseCategory(category), fetchPublicDocuments()]">{{category.title}}</p>
          </li>
        </ul>
      </section>
      <section class="document-list">
        <h2>Документи</h2>
        <hr />
        <!-- <p
          class="document-list-describtion"
        >На даній сторінці оприлюднюються офіційні документи: розпорядження, рішення, проекти рішень.</p>-->
        <form
          :class="[{'form-bigscreen-active': chosenCategory.subcategories.length}, 'search-form-bigscreen']"
        >
          <select @change="chooseSubcategory($event)" class="form-bigscreen-element" id>
            <option selected value="Усі документи">Усі документи</option>
            <option
              v-for="(subcat, i) in chosenCategory.subcategories"
              :value="subcat"
              :key="i"
            >{{subcat}}</option>
          </select>
          <button
            @click.prevent="fetchPublicDocuments"
            class="form-bigscreen-element form-bigscreen-search"
          >Шукати документи</button>
        </form>

        <form class="search-form-smallscreen">
          <select v-model="chosenCategory" class="form-smallscreen-element" id>
            <option
              v-for="(category, i) in documentCategories"
              :value="category"
              :key="i"
            >{{category.title}}</option>
          </select>
          <select @change="chooseSubcategory($event)" class="form-smallscreen-element" id>
            <option selected value="Усі документи">Усі документи</option>
            <option
              v-for="(subcat, i) in chosenCategory.subcategories"
              :value="subcat"
              :key="i"
            >{{subcat}}</option>
          </select>
          <button
            @click.prevent="fetchPublicDocuments"
            class="form-smallscreen-element form-smallscreen-search"
          >Шукати документи</button>
        </form>

        <ul :class="['document-documents', documentsStatusClass]">
          <li v-for="(document, i) in documents" :key="i">
            <a :href="document.url" target="_blank" class="document-item">{{document.title}}</a>
          </li>
        </ul>
        <p
          :class="['document-list-describtion', documentsStatusClass]"
        >Не вдалося знайти жодних документів данного типу</p>
        <div :class="['loader', documentsStatusClass]">
          <div class="loader-wheel"></div>
          <div class="loader-text"></div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";

export default {
  name: "PublicDocuments",
  components: {
    Navigation
  },
  data: () => ({
    chosenCategory: { title: "", subcategories: [] },
    chosenSubcategory: "Усі документи",
    documentCategories: [
      { title: "Реєстраційні документи", subcategories: [] },
      {
        title: "Звітність",
        subcategories: ["Фінансова", "Податкова", "Статистична", "Медична"]
      },
      {
        title: "Договори з НСЗУ",
        subcategories: []
      },
      {
        title: "Оренда",
        subcategories: [
          "Договори",
          "Зміни",
          "Вільні приміщення",
          "Повідомлення"
        ]
      },
      {
        title: "Публічні закупівлі",
        subcategories: [
          "Річні плани та зміни до них;",
          "Спрощені закупівлі",
          "Процедури закупівлі",
          "COVID-закупівлі"
        ]
      },
      {
        title: "Охорона праці",
        subcategories: []
      },
      { title: "Антикорупційні заходи", subcategories: [] }
    ],
    documents: [],
    documentsStatusClass: "documents-not-found"
  }),
  methods: {
    setDocumentsStatusClass(className) {
      this.documentsStatusClass = className;
    },
    chooseCategory(category) {
      this.chosenCategory = category;
      this.chosenSubcategory = "Усі документи";
    },
    chooseSubcategory(event) {
      this.chosenSubcategory = event.target.value;
    },
    fetchPublicDocuments() {
      const category = this.chosenCategory;
      const subcat = this.chosenSubcategory;
      let subcategoryInURL = "";
      if (subcat !== "Усі документи") {
        subcategoryInURL = `subcategory=${subcat}`;
      }
      this.setDocumentsStatusClass("documents-loading");

      fetch(
        `/api/v1/public-documents/?category=${category.title}&${subcategoryInURL}`
      )
        .then(data => data.json())
        .then(response => {
          this.documents = response.data;
          if (this.documents.length) {
            return this.setDocumentsStatusClass("documents-loaded");
          }
          return this.setDocumentsStatusClass("documents-not-found");
        })
        .catch(error => {
          console.log(error);
          return this.setDocumentsStatusClass("documents-not-found");
        });
    }
  },
  mounted() {
    this.chooseCategory(this.documentCategories[0]);
    this.fetchPublicDocuments();
  }
};
</script>
<style scoped>
.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto;
}

h3.heading {
  color: #4e5154;
  margin: 30px auto 30px 40px;
  font-size: 34px;
  text-align: left;
}

h3.heading::after {
  content: " ";
  display: block;
  width: 100%;
  max-width: 200px;
  height: 3px;
  top: 100%;
  left: 0;
  background: #aec3de;
}

.public-documents {
  width: calc(100% - 30px);
  max-width: 1250px;
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 50px auto;
  box-shadow: 3px 5px 9px 0 rgba(0, 0, 0, 0.36),
    5px 8px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.document-categories {
  display: flex;
  flex-direction: column;
  background: #6ab9df;
  height: inherit;
  width: 350px;
}

h2.document-categories-heading {
  margin: 20px auto 10px auto;
}

.categories-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 5px auto 45px auto;
}

.document-category {
  width: calc(100%);
  margin: 1px auto;
}

.document-category p {
  cursor: pointer;
  margin: 8px 15px 0 15px;
  padding: 13px 10px;
  background: #e0dfdcbb;
  text-align: left;
  font-size: 17px;
}

.document-category p:hover {
  background: #e0dfdcee;
}

.document-category .subcategories {
  font-size: 15px;
  margin-left: 30px;
  text-align: left;
}

.document-list {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: calc(100% - 350px);
  background: #e0e6eb;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.2) inset,
    5px 0 5px 0 rgba(0, 0, 0, 0.14) inset;
  justify-content: flex-start;
}

.document-list h2 {
  text-align: left;
  font-size: 32px;
  margin: 30px auto 30px 50px;
}

.document-list .document-list-describtion.documents-not-found {
  margin: 20px auto 20px 20px;
  align-self: flex-start;
}

.document-list .document-list-describtion.documents-loading,
.document-list .document-list-describtion.documents-loaded {
  display: none;
}

.search-form-bigscreen {
  margin: 15px 20px 10px 20px;
  width: calc(100% - 30px);
  max-width: 600px;
  height: auto;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #78bdeecc;
  border-radius: 4px;
  padding: 15px;
  align-self: flex-start;
}

.form-bigscreen-active {
  display: flex;
}

.form-bigscreen-element {
  padding: 6px;
  font-size: 17px;
  min-width: 250px;
  border-radius: 2px;
  outline-width: 0px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  text-align: left;
  transition: 0.3s;
}

.form-bigscreen-search:hover {
  box-shadow: rgba(0, 0, 0, 0.2);
  background: #3f4c73;
  color: white;
}

.search-form-smallscreen {
  display: none;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  align-self: center;
  background: #78bdeecc;
  border-radius: 4px;
  padding: 15px 5px;
  margin: 15px auto 5px auto;
}

.form-smallscreen-element {
  padding: 4px;
  font-size: 16px;
  min-width: 240px;
  border-radius: 2px;
  outline-width: 0px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  text-align: left;
  margin: 10px auto;
}

ul.document-documents.documents-loaded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 30px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

ul.document-documents.documents-loading,
ul.document-documents.documents-not-found {
  display: none;
}

.document-documents li {
  align-self: flex-start;
  margin: 5px 35px;
  list-style: disc;
}

a.document-item {
  color: rgb(43, 51, 75);
  font-size: 18px;
  text-decoration: underline;
}

.loader.documents-loading {
  width: 60px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader.documents-loaded,
.loader.documents-not-found {
  display: none;
}

.loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid rgb(43, 51, 75);
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
}

.loader-text {
  color: rgb(43, 51, 75);
  font-family: arial, sans-serif;
}

.loader-text:after {
  content: "Завантаження";
  animation: load 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    content: "Завантаження";
  }
  33% {
    content: "Завантаження.";
  }
  67% {
    content: "Завантаження..";
  }
  100% {
    content: "Завантаження...";
  }
}

@media screen and (max-width: 940px) {
  .document-categories {
    display: flex;
    flex-direction: column;
    background: #6ab9df;
    height: inherit;
    width: 250px;
  }

  h2.document-categories-heading {
    font-size: 22px;
  }

  .categories-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 5px auto 45px auto;
  }

  .document-category p {
    margin: 8px 15px 0 15px;
    font-size: 16px;
  }

  .document-category p:hover {
    background: #e0dfdcee;
  }

  .document-list {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: calc(100% - 250px);
    background: #e0e6eb;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.2) inset,
      5px 0 5px 0 rgba(0, 0, 0, 0.14) inset;
    justify-content: flex-start;
  }

  .document-list h2 {
    text-align: left;
    font-size: 24px;
    margin: 20px auto 20px 35px;
  }

  .document-list .document-list-describtion {
    margin: 20px auto 20px 20px;
    align-self: flex-start;
  }

  .search-form-bigscreen {
    max-width: 450px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #78bdeecc;
    border-radius: 4px;
    padding: 10px;
    align-self: flex-start;
  }

  .form-bigscreen-element {
    padding: 6px;
    font-size: 16px;
    min-width: 180px;
    border-radius: 2px;
    outline-width: 0px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    text-align: left;
  }
}

@media screen and (max-width: 690px) {
  h3.heading {
    margin: 20px auto 20px 30px;
    font-size: 28px;
  }

  .document-categories {
    width: 0;
  }

  .document-list {
    width: 100%;
  }

  .search-form-bigscreen {
    display: none;
  }

  .search-form-smallscreen {
    display: flex;
  }

  ul.document-documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    margin: auto;
    overflow-x: hidden;
  }

  .document-documents li {
    align-self: flex-start;
    margin: 2px 20px;
    list-style: disc;
  }

  a.document-item {
    color: rgb(43, 51, 75);
    font-size: 17px;
    text-decoration: underline;
  }
}

@media screen and (max-width: 550px) {
  .form-smallscreen-element {
    min-width: unset;
    width: 100%;

    margin: 10px;
  }
}
</style>